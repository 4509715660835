import React, { Component } from "react";
import { Link } from "react-router-dom";

class HostForgotPassword extends Component {
  state = {};
  render() {
    return (
      <div className="page-content">
        <div className="prov-login">
          <div className="log-head">
            <h4>Passwort zurück setzen</h4>
            <p>
            Geben Sie die Ihrem Konto zugeordnete E-Mail-Adresse ein, und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts per E-Mail.
            </p>
          </div>
          <form className="top1 prov-login-form">
            <div className="form-group input-group">
              <input
                type="text"
                className="form-control"
                placeholder="E-Mail"
              />
            </div>
            <button className="green-btn bottom1 btn-block">Link zusenden</button>
          </form>
        </div>
      </div>
    );
  }
}

export default HostForgotPassword;
