import React, { Component } from "react";
import Helper from "../Helper/Helper";
import Loader from "../Helper/Loader";
import ToastDemo from "../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { Link } from "react-router-dom";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { DatePicker } from "@y0c/react-datepicker";
import dayjs from "dayjs";
import { apiConstants } from "../../components/Constant/constants";

import configuration from "react-global-configuration";

import moment from "moment";
import 'dayjs/locale/de'
 
// delivery prop locale string  


class LandingPage extends Helper {
    state = {
        first_block: null,
        mainData: null,
        loading: true,
        second_block: null,
        formData: {
            checkin: "",
            checkout: "",
            latitude: "",
            longitude: ""
        }
    };
    constructor(props) {
        super(props);

        // States and props usage
    }

    componentDidMount() {
        // Call api function
        this.getDefaultLeavingTime();
    }

    renderAutoComplete = () => {
        const { google } = this.props;
        if (!google) {
            return;
        }
        const autocomplete = new google.maps.places.Autocomplete(
            this.autocomplete,
            { types: ["geocode"] }
        );

        autocomplete.setFields(["address_component", "geometry", "name"]);

        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            console.log("Place", place);
            if (!place.geometry) return;
            this.setState({ position: place.geometry.location });
            const formData = { ...this.state.formData };
            formData["latitude"] = place.geometry.location.lat();
            formData["longitude"] = place.geometry.location.lng();
            let full_address = "";
            place.address_components.map(
                address =>
                    (full_address =
                        full_address == ""
                            ? address.long_name
                            : full_address + "," + address.long_name)
            );
            formData["full_address"] = full_address;

            this.setState({ formData });
        });
    };

    showParking = event => {
        event.preventDefault();

        if (!this.state.formData.full_address) {
            ToastDemo(
                this.props.toastManager,
                "Bitte Standort angeben",
                "error"
            );
            return false;
        }
        if (!this.state.formData.checkin) {
            ToastDemo(this.props.toastManager, "Check-In angeben", "error");
            return false;
        }

        if (!this.state.formData.checkout) {
            ToastDemo(this.props.toastManager, "Check-Out angeben", "error");
            return false;
        }

        this.props.history.push("/search", this.state.formData);
    };

    getArrivingDateandTime = title => (...args) => {
        const formData = { ...this.state.formData };
        const datess = dayjs(args[0]).format("YYYY-MM-DD HH:mm:ss");
        formData["checkin"] = datess;
        this.setState({ formData });
    };

    getOutDateandTime = title => (...args) => {
        const formData = { ...this.state.formData };
        const datess = dayjs(args[0]).format("YYYY-MM-DD HH:mm:ss");
        formData["checkout"] = datess;
        this.setState({ formData });
    };

    getDefaultLeavingTime = () => {
        var oldDateObj = new Date();
        var newDateObj = new Date();
        newDateObj.setTime(oldDateObj.getTime() + 60 * 60 * 1000);

        return dayjs(newDateObj).format("YYYY-MM-DD HH:mm");
    };
    getDefaultArravingTime = () => {
        return dayjs(new Date()).format("YYYY-MM-DD HH:mm");
    };

    disableDay = date => {
        return moment() >= date;
    };

    render() {
        let load = new Loader();
        const { loading, mainData, second_block } = this.state;
        let renderSearch = (
            <div>
                <div className="form-group">
                    <label>Ort</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Wo möchtest du heute Parken?"
                        onFocus={this.renderAutoComplete}
                        ref={ref => (this.autocomplete = ref)}
                    />
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        <label>Ankunft</label>
                        <div className="">
                            <DatePicker
                                // initialDate={this.getDefaultArravingTime()}
                                onChange={this.getArrivingDateandTime(
                                    "Range DatePicker"
                                )}
                                includeTime
                                
                                placeholder="Datum & Uhrzeit"
                                minDate={moment().toDate()}
                                disableDay={this.disableDay}

                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label>Abfahrt</label>
                        <DatePicker
                            // initialDate={this.getDefaultLeavingTime()}
                            onChange={this.getOutDateandTime(
                                "Range DatePicker"
                            )}
                            includeTime
                            
                            placeholderText="Datum & Uhrzeit"
                            minDate={moment().toDate()}
                            disableDay={this.disableDay}
                        />
                    </div>
                </div>
                <div>
                    <button
                        className="cmn-btn green-btn mt-5"
                        onClick={this.showParking}
                    >
                        Parkplätze suchen
                    </button>
                </div>
            </div>
        );
        return (
            <div className="main">
                <div
                    className="banner-sec bg-img"
                    style={{
                        backgroundImage: `url('../assets/img/parking/home-banner.jpg')`
                    }}
                >
                    <div className="site-content">
                        <div className="banner-inner row">
                            <div className="col-md-6">
                                <div className="banner-left pos-rel"></div>
                            </div>
                            <div className="col-md-6">
                                <div className="banner-right">
                                    <h1 className="banner-tit">
                                        Finde in wenigen Sekunden deinen Parkplatz
                                    </h1>
                                    <p className="banner-txt">
                                        Wähle von über tausend möglichen
                                        Parkplätzen aus und reserviere in
                                        Sekunden. Über 2 Millionen Fahrer
                                        vertrauen PNP-Parking.
                                    </p>
                                    <div className="banner-content">
                                        <ul
                                            className="nav nav-justified nav-tabs "
                                            role="tablist"
                                        >
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                data-toggle="tab"
                                                href="#hourly"
                                                role="tab"
                                                aria-selected="true"
                                            >
                                                Stündlich / Täglich
                                            </a>
                                        </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link "
                                                    data-toggle="tab"
                                                    href="#hourly"
                                                    role="tab"
                                                    aria-selected="true"
                                                >
                                                    
                                                    Monatlich
                                                </a>
                                            </li>
                                            {/* <li className="nav-item">
                        <a
                          className="nav-link"
                          id="profile-tab"
                          data-toggle="tab"
                          href="#monthly"
                          role="tab"
                          aria-selected="false"
                        >
                          Monthly
                        </a>
                      </li> */}
                                        </ul>
                                        <div className="tab-content search-content">
                                            <div
                                                className="tab-pane fade show active"
                                                id="hourly"
                                                role="tabpanel"
                                            >
                                                {renderSearch}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="process-section park-section">
                    <div className="container">
                        <div className="sec-head text-center">
                            <div className="title-line"></div>
                            <h2>Nie wieder Parkplatz suchen</h2>
                            <p>
                                Die Vorteile von PNP-Parking auf einem Blick
                            </p>
                        </div>
                        <div className="sec-content row">
                            <div className="col-md-4">
                                <div className="features-box text-center">
                                    <img src="../assets/img/svg/parking.svg" />
                                    <h3>Immer & Überall</h3>
                                    <p>
                                        Wähle aus zahlreichen Parkplätzen in ganz Deutschland
                                    </p>
                                    <p>
                                        Finde die best Option für jede Fahrt
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="features-box text-center">
                                <img src="../assets/img/svg/parking-sign.svg" />
                                    <h3>Stressfrei</h3>
                                    <p>
                                        Verfügbarkeit, Preise und Eigenschaften auf einen Blick
                                    </p>
                                    <p>
                                        Mühelose Reservierung im vorraus
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="features-box text-center">
                                    
                                    <img src="../assets/img/svg/bill.svg" />
                                    <h3>Die Lösung für alles</h3>
                                    <p>
                                        Bezahle PNP-Parkplätze bequem über die App oder der Webseite
                                    </p>
                                    <p>
                                        Lass dich direkt auf deinen Parkplatz navigieren
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section
                    className="rent-section cross-shape p-70 bg-img"
                    style={{
                        backgroundImage: `url('../assets/img/parking-4.jpg')`
                    }}
                >
                    {/* <div className="rent-shape">
            <img src="../assets/img/parking/shape-1.png" />
          </div> */}
                    <div className="container">
                        <div className="rent-inner row pos-rel">
                            <div className="col-md-6">
                                <div className="rent-left">
                                    <h3 className="white-color rent-tit">
                                        Verdiene dir als Vermieter,
                                        etwas bei PNP-Parking azu
                                    </h3>
                                    <p className="white-color rent-txt">
                                        Registriere deinen Parkplatz kostenlos in wenigen Minuten.
                                    </p>
                                    <a href="#" className="white-btn">
                                        Jetzt Vermieter werden
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="download-section p-70">
                    <div className="container">
                        <div className="sec-head mb-20">
                            <div className="title-line m-l"></div>
                            <h2>
                                Hol dir Deutschland's beste <span className="green-color">Parking-App</span>
                            </h2>
                            <p>
                                
                            </p>
                        </div>
                        <div className="sec-content">
                            <div className="row">
                                <div className="col-md-5">
                                    
                                    
                                    <p className="sec-txt mb-20">
                                      Wer kennt es nicht: Man fährt von Häuserblock zu Häuserblock und findet einfach keinen Parkplatz. Die Nerven liegen blank, man verspätet sich und oft ist man, wenn man endlich was gefunden hat, gezwungen weit zurückzulaufen.
                                      Auch deutsche Großstädte haben inzwischen ein großes Problem mit bezahlbaren Parkplätzen. Aus diesem Grund haben wir PNP Parking entwickelt.
                                      Wir helfen dir innerhalb von Sekunden aus deinem Auto heraus, einen passenden Parkplatz zu finden und navigieren dich sogar über unsere App hin!
                                    </p>
                                    <p className="sec-txt mb-20">
                                        Lade dir jetzt unsere App kostenlos runter und spar dir in Zukunft das lästige Parkplatzsuchen in Großstädten!
                                    </p>
                                    {/* <div className="send-link-wrap mb-20">
                    <div className="input-group dropdown">
                      <input
                        type="text"
                        className="form-control form-control-lg dropdown-toggle"
                        data-toggle="dropdown"
                        placeholder="try 'london'"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon">
                          Send Link
                        </span>
                      </div>
                    </div>
                  </div> */}
                                    <div className="download-icon-wrap">
                                        <h4 className="download-icon-tit">
                                            HIER GEHTS ZUM DOWNLOAD
                                        </h4>

                                        <a
                                            href={configuration.get(
                                                "configData.playstore_user"
                                            )}
                                            className="download-icon"
                                        >
                                            <img src="../assets/img/parking/googleplay.svg" />
                                        </a>

                                        <a
                                            href={configuration.get(
                                                "configData.appstore_user"
                                            )}
                                            className="download-icon"
                                        >
                                            <img src="../assets/img/parking/appstore.svg" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="download-img">
                                        <img src="../assets/img/parking/mobile-app.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: apiConstants.google_api_key
})(withToastManager(LandingPage));
