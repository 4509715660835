import React, { Component } from "react";

class FaqSection extends Component {
  state = {};
  render() {
    return (
      <div className="site-content">
        <div className="host-top-bottom-spacing">
          <div className="host-section-head">
            <h1>Häufig gestellte Fragen</h1>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h4 className="faq-text">Getting Started</h4>
              <div id="accordion-faq" className="faq">
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse1"
                    >
                      <span>
                        <i className="fas fa-chevron-down" />
                      </span>
                      Kann ich meine Parkplätze bei PNP-Parking listen?
                    </a>
                  </div>
                  <div id="collapse1" className="collapse">
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse2"
                    >
                      <span>
                        <i className="fas fa-chevron-down" />
                      </span>
                      Lorem ipsum dolor sit amet
                    </a>
                  </div>
                  <div id="collapse2" className="collapse">
                    <div className="card-body">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse3"
                    >
                      <span>
                        <i className="fas fa-chevron-down" />
                      </span>
                      Lorem ipsum dolor sit amet
                    </a>
                  </div>
                  <div id="collapse3" className="collapse">
                    <div className="card-body">
                      
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h4 className="faq-text">Einnahmen</h4>
              <div id="accordion-faq" className="faq">
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse4"
                    >
                      <span>
                        <i className="fas fa-chevron-down" />
                      </span>
                      Lorem ipsum dolor sit amet
                    </a>
                  </div>
                  <div id="collapse4" className="collapse">
                    <div className="card-body">
                      
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse5"
                    >
                      <span>
                        <i className="fas fa-chevron-down" />
                      </span>
                      Lorem ipsum dolor sit amet
                    </a>
                  </div>
                  <div id="collapse5" className="collapse">
                    <div className="card-body">
                      
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse6"
                    >
                      <span>
                        <i className="fas fa-chevron-down" />
                      </span>
                      Lorem ipsum dolor sit amet
                    </a>
                  </div>
                  <div id="collapse6" className="collapse">
                    <div className="card-body">
                      
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FaqSection;
