import React, { Component } from "react";
import CalForm from "../Helper/calForm";
import FaqSection from "../Helper/faqSection";
import ToastDemo from "../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { Link } from "react-router-dom";

import configuration from "react-global-configuration";

class BecomeAHost extends Component {
  state = {};
  componentDidMount() {
    if (this.props.location.state != null) {
      ToastDemo(
        this.props.toastManager,
        this.props.location.state.error,
        "error"
      );
    }
  }
  render() {
    return (
      <div>
        <div
          className="host-banner-sec"
          style={{
            backgroundImage: `url('../../../assets/img/parking-5.jpg')`
          }}
        >
          <div className="host-banner-sec-overlay">
            <div className="site-content">
              <div className="row">
                <div className="col-md-6 col-lg-6 col-xl-5 host-banner-aligncenter">
                  <div className="">
                    <h2 className="host-banner-subhead">Zusätzliches Einkommen ohne zusätzliche Arbeit</h2>
                    <h1 className="host-banner-head">
                      Verdiene als<br/>PNP-Vermieter<br/>Geld dazu
                    </h1>
                    <Link  to={"/host/register"} className="green-btn">
                      Parkplatz vermieten
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
{/* 
        <div className="host-details-sec banner-mobileview">
          <div className="host-details-head">
            <h2>Find out what top hosts earn in your area</h2>
          </div>
          <form className="host">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="bangaluru"
              />
            </div>
            <div className="form-group">
              <select className="form-control">
                <option>4 guests</option>
                <option>1 guests</option>
                <option>2 guests</option>
                <option>3 guests</option>
                <option>4 guests</option>
              </select>
            </div>
            <div className="form-group">
              <select className="form-control">
                <option>Entire palce</option>
                <option>Private room</option>
                <option>shared room</option>
              </select>
            </div>
            <div>
              <h1 className="amount">₹18,296</h1>
              <h4 className="amount-subhead">per month</h4>
            </div>
            <div className="mt-4">
              <button className="pink-btn">get started</button>
            </div>
          </form>
        </div> */}

        <div className="site-content mb-4">
          <div className="host-top-bottom-spacing">
            <div className="host-section-head">
              <h1>Wie vermiete ich meinen Parkplatz mit {configuration.get("configData.site_name")}</h1>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="media safety">
                  <img
                    src="../assets/img/icons8/icons8-form-100.png"
                    alt="safety-icon"
                    className="mb-3 rounded-circle"
                  />
                  <div className="media-body">
                    <h2>Stelle deinen Parkplatz kostenlos ein</h2>
                    <p>
                      In nur wenigen Minuten erstellst du dein Inserat nach individueller Verfügbarkeit und mit einfach auswählbaren Parkplatzeigenschaften.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="media safety">
                  <img
                    src="../assets/img/icons8/icons8-handshake-100.png"
                    alt="safety-icon"
                    className="mb-3 rounded-circle"
                  />
                  <div className="media-body">
                    <h2>Dein Stellplatz wird gebucht</h2>
                    <p>
                      Dein Eintrag erscheint bei relevanten suchen automatisch. <br/>Wenn ein Nutzer dann dein Stellplatz sucht kann der Zugang über das System oder Persönlich ausgetauscht werden.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="media safety">
                  <img
                    src="../assets/img/icons8/icons8-profit-100.png"
                    alt="safety-icon"
                    className="mb-3 rounded-circle"
                  />
                  <div className="media-body">
                    <h2>Beobachte wie deine Einnahmen wachsen</h2>
                    <p>
                      Im Vermieter Dashboard behälst du alle Vermietungen im Blick, kannst sie verwalten und siehst deine Einnahmen in der Übersicht.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <FaqSection /> */}

        <div
          className="host-footer-img mt-4"
          style={{
            backgroundImage: `url('../../../assets/img/parking/parking-host-bottom.svg')`
          }}
        >
          <div className="site-content">
            <div className="row">
              <div className="col-sm-8 col-md-7 col-lg-6 col-xl-5">
                <div className="host-footer-content">
                  <div>
                    <div className="host-footer-content-head">
                      <h1>Liste jetzt dein Parkplatz</h1>
                    </div>
                    <a href="#" className="green-btn">
                      Jetzt vermieten
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="download-section p-70 ">
                    <div className="container">
                        <div className="sec-head mb-20">
                            <div className="title-line m-l"></div>
                            <h2>
                                Hol dir Deutschland's beste <span className="green-color">Parking-App</span>
                            </h2>
                            <p>
                                
                            </p>
                        </div>
                        <div className="sec-content">
                            <div className="row">
                                <div className="col-md-5">
                                    
                                    <p className="sec-txt mb-20">
                                    Ob Garage oder offener Parkplatz, mit PNP Parking kannst du deine unbenutzte Parkfläche spielend leicht vermieten und dir sogar Etwas dazuverdienen. Du hast jederzeit die volle Kontrolle über die Verfügbarkeit und Preise deines Parkplatzes. Außerdem informiert PNP Parking dich jedes Mal mit einer Push-Benachrichtigung auf dein Handy, sobald ein neuer Gast deinen Parkplatz gebucht hat.
                                    </p>
                                    <p className="sec-txt mb-20">
                                    Dein verdientes Geld kannst du dir in kurzer Zeit per Überweisung oder Paypal auf dein Konto auszahlen.
                                    </p>
                                    <p className="sec-txt mb-20">
                                    Installiere PNP-Parking noch heute und helfe Leuten, stressfreie Parkplätze zu finden!
                                    </p>
                                    {/* <div className="send-link-wrap mb-20">
                    <div className="input-group dropdown">
                      <input
                        type="text"
                        className="form-control form-control-lg dropdown-toggle"
                        data-toggle="dropdown"
                        placeholder="try 'london'"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon">
                          Send Link
                        </span>
                      </div>
                    </div>
                  </div> */}
                                    <div className="download-icon-wrap">
                                        <h4 className="download-icon-tit">
                                            HIER GEHTS ZUM DOWNLOAD
                                        </h4>

                                        <a
                                            href={configuration.get(
                                                "configData.playstore_user"
                                            )}
                                            className="download-icon"
                                        >
                                            <img src="../assets/img/parking/googleplay.svg" />
                                        </a>

                                        <a
                                            href={configuration.get(
                                                "configData.appstore_user"
                                            )}
                                            className="download-icon"
                                        >
                                            <img src="../assets/img/parking/appstore.svg" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="download-img">
                                        <img src="../assets/img/parking/mobile-app.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
      </div>
    );
  }
}

export default withToastManager(BecomeAHost);
